import React from 'react'
import Spotify from '../../node_modules/react-spotify-embed'

function SpotifyEmbed(props) {
  return (
    <p className="d-print-none">
      {props ? <Spotify wide link={props.embed_url} /> : null}
    </p>
  )
}

export default SpotifyEmbed