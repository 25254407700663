import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { repositoryConfigs } from '../utils/prismicPreviews'
import { RichText } from 'prismic-reactjs'
import { Row, Col } from 'react-bootstrap'
import Attachment from '../components/Attachment'
import TwoColSection from '../components/TwoColSection'
import SpotifyEmbed from '../components/SpotifyEmbed'
import LayoutAnleitungen from '../components/layout/LayoutAnleitungen'
import SEO from '../components/seo'
import AnleitungsList from '../components/AnleitungsList'

const StundeTemplate = ({ data }) => {
  
  // calc totalDuration
  let totalDuration = 0

  data.prismicStunde.data.anleitungen.map((anleitung) => {
    if (anleitung.anleitung.document) {
      return anleitung.anleitung.document.data.steps.map((stepName) => {
        if (stepName.active === true || stepName.active === null) {
          totalDuration += stepName.duration_mm
        }
      })
    }
  })

  const spotify = data.prismicStunde.data.playlist_embed
  const playlistName = data.prismicStunde.data.playlist_name

  const rightColContent = (
    <>
      <ul className="fs-4">
        <li>
          <strong>Dauer: {totalDuration} Min.</strong>
        </li>
        {playlistName !== null ? (
          <li>{data.prismicStunde.data.playlist_name}</li>
        ) : null}
        {data.prismicStunde.data.attachment.url ? (
          <li>
            <Attachment attachment={data.prismicStunde.data.attachment} />
          </li>
        ) : null}
      </ul>
      {spotify ? <SpotifyEmbed embed_url={spotify.embed_url} /> : null}
    </>
  )

  const leftColContent = (
    <>
      <h3>Ziel:</h3>
      {data.prismicStunde ? (
        <p className="fs-3">
          <RichText render={data.prismicStunde.data.goal.raw} />
        </p>
      ) : null}
    </>
  )

  if (!data) return null

  return (
    <LayoutAnleitungen>
      <SEO index="noindex" />
      <Col className="p-4 bg-light">
        <Row className="p-4 mt-4 mt-md-5 mb-4">
          <Col>
            <RichText render={data.prismicStunde.data.title.raw} />
          </Col>
        </Row>
        <Row className="mt-4 mt-md-5 mb-4 mb-md-5">
          <Col>
            <Row className="section-2">
              <Col>
                <TwoColSection
                  leftColContent={leftColContent}
                  rightColContent={rightColContent}
                />
                <Row>
                  <Col>
                    <h4>Notizen</h4>
                    <p className="fs-5">
                      <RichText render={data.prismicStunde.data.notes.raw} />
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="bg-secondary text-white p-4">
              <Col>
                <h2>Anleitungen</h2>
                <AnleitungsList
                  anleitungen={data.prismicStunde.data.anleitungen}
                  showStepsList={true}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </LayoutAnleitungen>
  )
}

export const query = graphql`
  query stundeQuery($id: String, $lang: String) {
    prismicStunde(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      url
      uid
      type
      id
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        title {
          raw
        }
        attachment {
          url
        }
        goal {
          raw
        }
        notes {
          raw
        }
        playlist_embed {
          embed_url
        }
        playlist_name
        anleitungen {
          anleitung {
            uid
            document {
              ... on PrismicAnleitung {
                data {
                  steps {
                    duration_mm
                    active
                    name {
                      text
                    }
                    text {
                      raw
                    }
                  }
                  title {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default withPrismicPreview(StundeTemplate, repositoryConfigs)
