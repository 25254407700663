import React from 'react'

function Attachment(props) {
  return (
    <>
      {props.attachment ? (
          <a href={props.attachment.url}>Attachment</a>
      ) : null}
    </>
  )
}

export default Attachment