import React from 'react'
import { RichText } from 'prismic-reactjs'

function StepsList({ steps, showStepsList }) {
  if (!showStepsList) return null

  return steps.map((stepName) => {
    if (stepName.active === true || stepName.active === null) {
      return (
        <ul key={stepName.index}>
          <li>
            <span className="fs-2">{stepName.name.text}</span>
            <span> ({stepName.duration_mm}`)</span>
            <RichText render={stepName.text.raw} />
          </li>
        </ul>
      )
    }
  })
}

export default StepsList
